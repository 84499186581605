
import { listProgramApproves } from '@/api/inventory/editing'

const getDefaultState = () => {
  return {
    curTaskInfo: {},
    indexSearchParams: null, // 首页搜索参数
    approvalRecordData: {},
    curApprovalRecord: {},
    curApprovalId: null,
    approvalRecordQuery: {}
  }
}
const state = getDefaultState()
const electronic = {
  state: state,
  mutations: {
    reset_state (state) {
      Object.assign(state, getDefaultState())
    },
    set_cur_task_info (state, data) {
      state.curTaskInfo = data
    },
    set_index_search_params (state, data) {
      state.indexSearchParams = data
    },
    set_approval_record_data (state, data) {
      state.approvalRecordData = data
    },
    set_cur_approval_record (state, data) {
      state.curApprovalRecord = data
    },
    set_cur_approval_id (state, data) {
      state.curApprovalId = data
    },
    set_approval_record_query (state, data) {
      state.approvalRecordQuery = data
    }
  },
  actions: {
    getApporvalRecordData ({ state, commit }, query) {
      const newQuery = Object.assign(state.approvalRecordQuery, query)
      commit('set_approval_record_query', newQuery)

      return new Promise((resolve) => {
        listProgramApproves(newQuery).then(res => {
          commit('set_approval_record_data', res)
          resolve(res)
        })
      })
    }
  }
}

export default electronic
