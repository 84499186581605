<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>
      <div slot="bottom_box_content">
        <component :is="curBottomComponent"></component>
      </div>

    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'

// 引入store模块
import electronicStoreModule from '@/store/modules/electronic'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    // LeftComponent: () => import('./components/Left'),
    // BottomComponent: () => import('./components/Bottom'),
    // RightComponent: () => import('./components/Right')

    // left
    Summary: () => import('./components/left/Summary'),
    Material: () => import('./components/left/Material.vue'),

    // bottom
    IndexTable: () => import('./components/bottom/IndexTable'),
    PlayList: () => import('./components/bottom/PlayList'),
    ApprovalDetail: () => import('./components/bottom/ApprovalDetail'),

    // right
    ContentList: () => import('./components/right/ContentList'),
    ApprovalHistory: () => import('./components/right/ApprovalHistory')
  },
  created () {
    if (!this.$store.hasModule('electronic')) {
      this.$store.registerModule('electronic', electronicStoreModule)
    }
  },
  mounted () {
    this.setShowLeftBlock(true)
    this.setShowRightBlock(true)
    this.setShowMap(false)
    this.setBottomHeight('90%')

    this.setLeftComponent('Material')
    this.setBottomComponent('IndexTable')
    this.setRightComponent('ApprovalHistory')
  },
  destroyed () {
    // 卸载动态store模块
    this.$store.commit('reset_state')
    this.$store.unregisterModule('electronic')
    this.setShowLeftBlock(true)
    this.setShowMap(true)
    this.setShowRightBlock(true)
    this.setBottomHeight('300px')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
