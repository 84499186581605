import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 查询播单列表
 */
export function listPrograms (data) {
  return request({
    url: '/ooh-inventory/v1/editing/listPrograms',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询播单明细列表
 */
export function listProgramDetails (data) {
  return request({
    url: '/ooh-inventory/v1/editing/listProgramDetails',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 调整播单明细顺序
 */
export function updateProgramOrder (data) {
  return request({
    url: '/ooh-inventory/v1/editing/updateProgramOrder',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 增加播单明细
 */
export function addProgramDetail (data) {
  return request({
    url: '/ooh-inventory/v1/editing/addProgramDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 删除播单明细
 */
export function deleteProgramDetail (data) {
  return request({
    url: '/ooh-inventory/v1/editing/deleteProgramDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询播单中包含的客户和品牌
 */
export function getProgramInfo (data) {
  return request({
    url: '/ooh-inventory/v1/editing/getProgramInfo',
    method: 'get',
    params: data
  })
}

/**
 * 生成节目播放清单明细
 */
export function createProgramDetails (data) {
  return request({
    url: '/ooh-inventory/v1/editing/createProgramDetails',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 重新生成节目播放清单
 */
export function recreateProgramDetails (data) {
  return request({
    url: '/ooh-inventory/v1/editing/recreateProgramDetails',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 发起节目单审批
 */
export function startProgramExamine (data) {
  return request({
    url: '/ooh-inventory/v1/editing/startProgramExamine',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 审批查询节目单列表
 */
export function getApprovalPrograms (data) {
  return request({
    url: '/ooh-inventory/v1/editing/getApprovalPrograms',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询审批记录
 */
export function listProgramApproves (data) {
  return request({
    url: '/ooh-inventory/v1/editing/listProgramApproves',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 撤销审批
 */
export function terminateProgramExamine (data) {
  return request({
    url: '/ooh-inventory/v1/editing/terminateProgramExamine',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 重新发起审批
 */
export function reStartProgramExamine (data) {
  return request({
    url: '/ooh-inventory/v1/editing/reStartProgramExamine',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 替换节目素材
 */
export function replaceProgramDetail (data) {
  return request({
    url: '/ooh-inventory/v1/editing/replaceProgramDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}
